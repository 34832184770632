const images = {
  logo: require("../assets/images/logo.png"),
  banner: require("../assets/images/banner.jpg"),
  banner1: require("../assets/images/banner-1.jpg"),
  banner2: require("../assets/images/banner-2.jpg"),
  product1: require("../assets/images/feature-1.jpg"),
  product2: require("../assets/images/feature-2.jpg"),
  product3: require("../assets/images/feature-3.jpg"),
  product4: require("../assets/images/feature-4.jpg"),
  product5: require("../assets/images/feature-3.jpg"),
  product6: require("../assets/images/feature-6.jpg"),
  product7: require("../assets/images/feature-7.jpg"),
  product8: require("../assets/images/feature-8.jpg"),
  lp1: require("../assets/images/lp-1.jpg"),
  lp2: require("../assets/images/lp-2.jpg"),
  lp3: require("../assets/images/lp-3.jpg"),
  cat1: require("../assets/images/cat-1.jpg"),
  cat2: require("../assets/images/cat-2.jpg"),
  cat3: require("../assets/images/cat-3.jpg"),
  cat4: require("../assets/images/cat-4.jpg"),
  cat5: require("../assets/images/cat-5.jpg"),
  blog1: require("../assets/images/blog-1.jpg"),
  blog2: require("../assets/images/blog-2.jpg"),
  blog3: require("../assets/images/blog-3.jpg"),
  productDetails1: require("../assets/images/product-details-1.jpg"),
  productDetails2: require("../assets/images/product-details-2.jpg"),
  productDetails3: require("../assets/images/product-details-3.jpg"),
  productDetails4: require("../assets/images/product-details-4.jpg"),
  productDetails5: require("../assets/images/product-details-5.jpg"),
  cart1: require("../assets/images/cart-1.jpg"),
  cart2: require("../assets/images/cart-2.jpg"),
  cart3: require("../assets/images/cart-3.jpg"),
};

export default images;
