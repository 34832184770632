import React from "react";
import classNames from "classnames/bind";
// styles
import styles from "./PageNotFound.module.css";
const cx = classNames.bind(styles);

const PageNotFound = () => {
  return <div>Page Not Found</div>;
};

export default PageNotFound;
